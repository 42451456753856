import React from 'react';
import Head from 'next/head';
import NotFoundContent from '@components/NotFound/NotFoundContent';
import { getSiteUrl } from '@utils/config';

const NotFoundPage = () => {
  return (
    <>
      <Head>
        <title>Not Found | Clayton</title>
        <meta
          name="description"
          content=" Clayton has been providing affordable, quality homes since 1956. Learn more about our recent company announcements and publicly released information."
        />
        <meta
          property="og:image"
          content={`${getSiteUrl('/public-assets/images/clayton-logo.jpg')}`}
        />
      </Head>

      <NotFoundContent />
    </>
  );
};

export default NotFoundPage;
